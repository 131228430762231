.container_agradecimiento {
    position: absolute;
    left: 50%;
    transform: translate(-50%, +25%);
}

.form_container_agr {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 460px;
    background-color: #F3DAE3;
    box-shadow: 3px 4px 36px -6px rgba(0, 0, 0, 0.4);
    border-radius: 50px;
}

.from_heading_agr {
    font-size: 25px;
    font-weight: 400;
    color: #2c444e;
    box-sizing: border-box;
    margin-top: 80px;
    margin-left: 110px;
}

.btns {
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-bottom: 12px !important;
    border-radius: 20px !important;
    margin: 50px 0 0 70px !important;
    outline: none !important;
    border: none !important;
    cursor: pointer !important;
    height: 50px;
}
.btn1 {
    color: black !important;
    background-color: white !important;

}
.btn2 {
    color: white !important;
    background-color: #8D304F !important;
}

.text {
    font-size: 14px;
    color: #2c444e;
    margin: 5px 0;
    padding: 0;
}

.text>a {
    font-size: 16px;
    font-weight: 500;
    color: #ffc801;
}

.tipography {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #8D304F;
    font-size: 1.3rem !important;
    transition: .3s !important;
    margin-top: 6px !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    font-size: medium !important;
}

/****/