/**************************/
/**LOGIN NEW**/
.containerLogin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-47%, +13%);
}


.form_container {
    display: flex;
    /* width: 600px; */
    width: 700px;
    /* height: 460px; */
    height: 600px;
    background-color: #F3DAE3;
    box-shadow: 3px 4px 36px -6px rgba(0, 0, 0, 0.4);
    border-radius: 50px;
}

.right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.from_heading {
    font-size: 20px;
    font-weight: 400;
    /* color: #2c444e !important; */
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: -40px
}

.container-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.btn {
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 12px 25px !important;
    color: white !important;
    background-color: #8A133D !important;
    border-radius: 12px !important;
    margin-top: 15px !important;
    outline: none !important;
    border: none !important;
    cursor: pointer !important;
    width: 100%;
    height: 50px;
}

.btn_register {
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 12px 25px !important;
    color: white !important;
    background-color: #8A133D !important;
    border-radius: 12px !important;
    margin-top: 15px !important;
    outline: none !important;
    border: none !important;
    cursor: pointer !important;
    width: 80%;
    height: 50px;
}

.text {
    font-size: 14px;
    color: #2c444e;
    margin: 5px 0;
    padding: 0;
}

.text>a {
    font-size: 16px;
    font-weight: 500;
    color: #ffc801;
}

.google_btn {
    width: 230px;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #2c444e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.google_btn>img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.google_btn>span {
    margin-left: 10px;
}

.tipography {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #8D304F;
    font-size: 1.3rem !important;
    transition: .3s !important;
    margin-top: 6px !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    font-size: medium !important;
}

.textCuenta {
    color: #000000;
    margin-top: 6px;
    font-weight: bold;
    font-size: medium;
}

.tipography:hover {
    color: #8A133D;
    cursor: pointer;
}

.containerRegistrarme {
    display: flex;
}

.lineaSeparador {
    width: 100%;
    border: 2px solid #8D304F;
    margin-top: 10px;
    margin-bottom: 3px;
}

/****/