.radios {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-question {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: "100%";
	margin-top: "2rem";
	padding: "3rem"
}
.container-boton {
	border: 1px solid rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-enviar {
	/* width: 200px; */
	font-size: 18px !important; 
    font-weight: 500 !important;
    padding: 12px 25px !important;
    color: white !important;
    background-color: #8A133D !important;
    border-radius: 12px !important;
    margin: auto !important;
    outline: none !important;
    border: none !important;
    cursor: pointer !important;
    width: 15%;
	height: 50px;
}

.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
  }
  
  .spinner-wrapper {
	width: 80%; /* Ocupa el 80% del ancho de la pantalla */
  }