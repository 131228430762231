.floating-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .floating-button {
    width: 50px;
    height: 50px;
    background-color: #b31b55;
    color: white;
    border: none;
    border-radius: 50%;
    /* font-size: 10px; */
    cursor: pointer;
  }