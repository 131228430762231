.container_success {
    position: absolute;
    left: 50%;
    transform: translate(-50%, +25%);
}

.form_container_suc {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 460px;
    background-color: #F3DAE3;
    box-shadow: 3px 4px 36px -6px rgba(0, 0, 0, 0.4);
    border-radius: 50px;
}

.from_heading_suc {
    font-size: 25px;
    font-weight: 400;
    color: #2c444e;
    box-sizing: border-box;
    margin-top: 80px;
    margin-left: 110px;
}

.text_suc {
    color: #450018;
    font-size: 17px;
}

.icon-success{
    font-size: 50px !important;
    margin-left: 90px;
}